
import { Options, Vue } from "vue-property-decorator";
import last from "lodash/last";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import QrCodeScanner from "@/components/QrCodeScanner.vue";

@Options({
  components: { QrCodeScanner },
})
export default class QrCodeFindDrivingSchool extends Vue {
  public name = "QrCodeFindDrivingSchool";

  public onChangeResult(result: string) {
    if (result) {
      const splitUrl = result.split("/");
      if (splitUrl.length) {
        const drivingSchoolId = last(splitUrl);
        if (!isString(drivingSchoolId)) return;
        const parseToNumber = parseInt(drivingSchoolId);
        if (isNumber(parseToNumber)) this.$router.push({ name: "Register", params: { drivingSchool: drivingSchoolId } });
      }
    }
  }
}
